import { createRouter, createWebHistory } from "vue-router";
import Home from '../views/Home'
import Feedback from '../views/Feedback'
import Faq from '../views/Faq'
import Updates from '../views/Updates'
import SupportUs from '../views/SupportUs'
import Scoreboard from '../views/Scoreboard'
import GameHistory from '../views/History'
import NotFound from '../views/NotFound'

const routes = [
  {
     path: '/',
     name: 'Home',
     component: Home,
     meta: {
       title: '',
     }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    meta: {
      title: ' - Feedback',
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      title: ' - FAQ',
    }
  },
  {
    path: '/updates',
    name: 'Updates',
    component: Updates,
    meta: {
      title: ' - Updates',
    }
  },
  {
    path: '/supportus',
    name: 'SupportUs',
    component: SupportUs,
    meta: {
      title: ' - Support Us',
    }
  },
  {
    path: '/scoreboard',
    name: 'Scoreboard',
    component: Scoreboard,
    meta: {
      title: ' - Scoreboard',
    }
  },
  {
    path: '/history',
    name: 'History',
    component: GameHistory,
    meta: {
      title: ' - History',
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '',
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const metaTitle = 'Tim Hortons Hockey Challenge Helper';
  document.title = metaTitle + `${to.meta.title}`;
  next();
})

export default router;
